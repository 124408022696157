import cn from 'classnames';
import { useRouter } from 'next/router';
import { LOCALE_DICT, VIEW_ALL_TOPS_DEFAULT } from '@constants';
import { COLLECTION_LISTS } from '@constants/contentful';
import { TopsTileNavigation } from '@components/collections';
import { Loading } from '@components/common';
import { NormalizedCollection } from '@ts/product';
import { useCollectionsQuery } from '@utils/react-query';
import styles from './CollectionCarousel.module.scss';

export type CollectionCarouselProps = {
	collections: Array<NormalizedCollection>;
	removePadding?: boolean;
};

export const CollectionCarousel = ({ removePadding = false }: CollectionCarouselProps) => {
	const classes = cn(styles['section'], {
		[styles['removePadding']]: removePadding,
	});
	const { locale } = useRouter();
	const countryCode = LOCALE_DICT[locale].countryCode;

	const { data: collections, isLoading } = useCollectionsQuery(COLLECTION_LISTS.ACTIVE, {
		queryKey: 'active-collections',
		type: 'active',
		withProducts: false,
		country: countryCode,
		queryRefreshVars: [locale],
	});

	if (!collections && isLoading) {
		return (
			<div className={styles['loading-wrapper']}>
				<Loading className={styles['loading-spinner']} />
			</div>
		);
	}

	return (
		<TopsTileNavigation
			collections={[VIEW_ALL_TOPS_DEFAULT, ...Object.values(collections)]}
			heading='Check Out Our Collections'
			subHeading='Choose from hundreds of Top Frames to switch up your everyday style!'
			className={classes}
		/>
	);
};

export default CollectionCarousel;

import { useState, useRef, FC } from 'react';
import dynamic from 'next/dynamic';
import cn from 'classnames';
import { Navigation, FreeMode } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { MEDIUM_WIDTH } from '@constants';
import { Spacer } from '@components/common';
import { Chevron } from '@components/icons';
import { Body, Heading } from '@components/typography';
import { useHasMounted, useIsMobile } from '@utils/hooks';
import { NormalizedCollection } from '@ts/product';

import 'swiper/css';
import styles from './TopsTileNavigation.module.scss';

const CollectionTile = dynamic(() => import('@components/common/CollectionTile/CollectionTile'));

type TopsTileNavigation = {
	collections: Omit<NormalizedCollection, 'products'>[];
	heading: string;
	subHeading?: string;
	className?: string;
};

const TopsTileNavigation: FC<TopsTileNavigation> = ({ collections, heading, subHeading, className = '' }) => {
	const isMobile = useIsMobile({ maxWidth: MEDIUM_WIDTH });
	const hasMounted = useHasMounted();
	const visibleCollections = collections ?? []
	const classes = cn(styles['section'], className);

	const [isBeginning, setIsBeginning] = useState(false);
	const [isEnd, setIsEnd] = useState(false);

	const navigationPrevRef = useRef(null);
	const navigationNextRef = useRef(null);

	const navigationRefs = {
		prevEl: navigationPrevRef.current,
		nextEl: navigationNextRef.current,
	};

	if (!hasMounted) {
		return null;
	}

	return (
		<section className={classes}>
			<div className={styles.tileContainer}>
				<Heading tag='h3'>{heading}</Heading>
				{subHeading ?
					<>
						<Body>{subHeading}</Body>
						<Spacer size={24} />
					</> : <Spacer size={16} />
				}
				<div className={styles.tileTrack}>
					<Swiper
						className={styles.tileSlider}
						modules={[Navigation, FreeMode]}
						navigation={!isMobile && navigationRefs}
						freeMode={{
							// Ref: https://swiperjs.com/swiper-api#free-mode
							enabled: isMobile,
							sticky: true,
							momentum: true,
							momentumRatio: 0.4,
							momentumBounceRatio: 0.1,
							momentumVelocityRatio: 0.1,
							minimumVelocity: 0.8, // High value prevents unwanted flicking effect on mobile for normal swiping speed
						}}
						width={isMobile ? 160 : 140}
						spaceBetween={16}
						slidesPerView={1}
						slidesPerGroup={isMobile ? 2 : 8}
						resistanceRatio={0}
						onSlideChange={swiper => {
							setIsBeginning(swiper.isBeginning);
							setIsEnd(swiper.isEnd);
						}}
						onSlidesGridLengthChange={swiper => {
							setIsBeginning(swiper.isBeginning);
							setIsEnd(swiper.isEnd);
							swiper.on('reachEnd', () => {
								setIsEnd(true);
							});
						}}
					>
						{visibleCollections.length &&
							visibleCollections.map(collection => {
								return (
									<SwiperSlide key={collection.handle} className={styles.tile}>
										<CollectionTile collection={collection} hasLink={true} />
									</SwiperSlide>
								);
							})}

						<div className={`${styles.prevButton} ${isBeginning ? styles.hide : ''}`} ref={navigationPrevRef}>
							<Chevron extraClasses={styles.chevron} direction='left' />
						</div>

						<div className={`${styles.nextButton} ${isEnd ? styles.hide : ''}`} ref={navigationNextRef}>
							<Chevron extraClasses={styles.chevron} direction='right' />
						</div>
					</Swiper>
				</div>
			</div>
		</section>
	);
};

export default TopsTileNavigation;
